export const nftFilterDummyList = [
  {
    name: '국가',
    isOpen: false,
    list: [
      {
        key: 1,
        name: '프랑스',
        select: false,
      },
      {
        key: 2,
        name: '이탈리아',
        select: false,
      },
      {
        key: 3,
        name: '스페인',
        select: false,
      },
      {
        key: 4,
        name: '미국',
        select: false,
      },
    ],
  },
  {
    name: '지역',
    isOpen: false,
    list: [
      {
        key: 1,
        name: 'Bourgogne',
        select: false,
      },
      {
        key: 2,
        name: 'Bordeaux',
        select: false,
      },
      {
        key: 3,
        name: 'Alsace',
        select: false,
      },
      {
        key: 4,
        name: 'Champagne',
        select: false,
      },
    ],
  },
  {
    name: '분류',
    isOpen: false,
    list: [
      {
        key: 1,
        name: 'Red',
        select: false,
      },
      {
        key: 2,
        name: 'White',
        select: false,
      },
      {
        key: 3,
        name: 'Champagne',
        select: false,
      },
    ],
  },
  {
    name: '판매 상태',
    isOpen: false,
    list: [
      {
        key: 1,
        name: '판매중',
        select: false,
      },
      {
        key: 2,
        name: '판매 완료',
        select: false,
      },
    ],
  },
];

export const firstWineDummyList = [
  {
    status: 1,
    brand: 'Chateau Meyn',
    name: 'Domaine de La Romanee-Conti La Tache Grand Cru 2017',
    key: '1',
    price: {
      coin: 6627,
      won: 8609463,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220604/d8c14d3dff157.png',
  },
  {
    status: 2,
    brand: 'Chateau Meyn',
    name:
      'Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019',
    key: '0x09x08e09x456x154fsd',
    price: {
      coin: 951,
      won: 1235491,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png',
  },
  {
    status: 1,
    brand: 'Domaine Comte Georges de Vogue',
    name: 'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
    key: '4',
    price: {
      coin: 1136,
      won: 1475834,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png',
  },
  {
    status: 1,
    brand: 'Domaine Comte Georges de Vogue',
    name:
      'Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019',
    key: '5',
    price: {
      coin: 951,
      won: 1235491,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png',
  },
  {
    status: 1,
    brand: 'Domaine de La Romanee-Conti',
    name: 'Domaine de La Romanee-Conti La Tache Grand Cru 2017',
    key: '6',
    price: {
      coin: 6627,
      won: 8609463,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png',
  },
  {
    status: 1,
    brand: 'Domaine de La Romanee-Conti',
    name: 'Domaine de La Romanee-Conti Echezeaux Grand Cru 2018',
    key: '7',
    price: {
      coin: 3808,
      won: 4947161,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png',
  },
  {
    status: 1,
    brand: 'Louis Roederer',
    name: 'Louis Roederer Cristal 2009 1.5L Magnum',
    key: '8',
    price: {
      coin: 602,
      won: 782088,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/7f917b103c3d5.jpg',
  },
  {
    status: 3,
    brand: 'Pierre Girardin',
    name: 'Pierre Girardin Batard-Montrachet Grand Cru 2020',
    key: '9',
    price: {
      coin: 1270,
      won: 15000000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/13fd3fad4419c.png',
  },
];

export const secondWineDummyList = [
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] Louis Roederer Cristal 2009 1.5 Magnum ',
    key: 11,
    price: {
      coin: 230,
      won: 1280000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220602/b65093f040236.jpg',
  },
  {
    status: 2,
    brand: 'Cote de Nuits',
    name:
      '[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019 ',
    key: 12,
    price: {
      coin: 1023,
      won: 3450000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2019 ',
    key: 13,
    price: {
      coin: 1270,
      won: 2730000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2018 ',
    key: 14,
    price: {
      coin: 386,
      won: 2400000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220527/49d971f480586.png',
  },
  {
    brand: 'Cote de Nuits',
    name:
      '[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2018 ',
    key: 15,
    price: {
      coin: 621,
      won: 3180000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220519/694e43dfe6197.png',
  },
  {
    status: 3,
    brand: 'Cote de Nuits',
    name: '[NFT] DRC Echezeaux Grand Cru 2018 ',
    key: 16,
    price: {
      coin: 1270,
      won: 15000000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: 'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
    key: 17,
    price: {
      coin: 1270,
      won: 5260000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] Bollinger La Grande Année 2012 ',
    key: 18,
    price: {
      coin: 105,
      won: 470000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220519/d0c1e564d8f76.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] DRC La Tache Grand Cru 2017 ',
    key: 19,
    price: {
      coin: 3672,
      won: 27000000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png',
  },
  {
    status: 1,
    brand: 'Cote de Nuits',
    name: '[NFT] DRC La Tache Grand Cru 2017 ',
    key: 20,
    price: {
      coin: 3672,
      won: 27000000,
    },
    image: 'https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png',
  },
];
