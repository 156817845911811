<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Market</div>
      </div>
    </div>
    <div class="winex-wine-list-wrap">
      <div class="filter-wrap pc-filter-wrap">
        <!-- TODO :: 잠시 예외 처리 -->
        <div class="filter-title-wrap" @click="gettingReady">
          <h2 class="title">Filter</h2>
          <div class="filter" @click="getWineFilterList"></div>
        </div>

          <PcGroupCheckbox
            v-for="item in filterList"
            :key="item.name"
            :list="item.list"
            :title="item.name"
            :isOpen="item.open"
          />

      </div>
      <div class="wine-list-wrap">
        <div class="pc-list-wrap">
          <PcWineList
            :wine-list="viewWineList"
            :page-info="pageInfo"
            :move-page="getWineList"
          ></PcWineList>
        </div>
        <div class="mobile-list-wrap">
          <div class="wine-list-mobile-header">
            <!-- TODO :: 잠시 예외 처리 -->
            <!--<div class="filter-wrap" @click="openFilterModal">-->
              <div class="filter-wrap" @click="gettingReady">
              <div class="filter-icon"></div>
              <div>Filter</div>
            </div>
            <div></div>
          </div>
          <MobileWineList :wine-list="viewWineList" :next-page="nextPage">
          </MobileWineList>
          <FilterModal
            :closeFilterModal="closeFilterModal"
            :isOpen="filterModalIsOpen"
            :filterList="filterList"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PcWineList from '../../components/list/PcWineList';
import MobileWineList from '../../components/list/MobileWineList';
import getContractApi from '../../api/contract';
import {
  firstWineDummyList,
  secondWineDummyList,
} from '../../assets/dummy/nftFilterDummyList';
import PcGroupCheckbox from '../../components/checkbox/pcGroupCheckbox';
import FilterModal from '../../components/modals/FilterModal.vue';

export default {
  components: { MobileWineList, PcWineList, PcGroupCheckbox, FilterModal },
  created() {
    this.market = getContractApi().getWineMarket();
  },
  mounted() {
    // @TODO 로딩 중 화면
    this.market
      .fetchCountListedItems().then(count => this.totalSupply = count.toNumber()).finally(() => {
        // @TODO 로딩 완료
      });

    this.getWineFilterList();
  },
  data() {
    return {
      contract: null,
      totalSupply: 0,
      viewWineList: [],
      filterList: [],
      wineList: [],
      filterModalIsOpen: false,
      firstWineList: firstWineDummyList,
      secondWineList: secondWineDummyList,
      pageInfo: {
        current_page: 1,
        total_page: 100,
        item_per_page: 9,
      },
    };
  },
  watch: {
    totalSupply(value) {
      // value 기준? 처리
      const step = this.pageInfo.item_per_page;
      const total = Math.ceil(value / step);
      this.$set(this.pageInfo, 'total_page', total);
      this.updatePageItems(this.pageInfo.current_page);
    },
    wineList() {
      this.show(this.pageInfo.current_page);
    }
  },
  methods: {
    getWineFilterList() {
      this.$store
        .dispatch('wineFilterList/getWineFilterList', {})
        .then((data) => {
          this.filterList = data;
          // console.log()
        });
    },
    openFilterModal() {
      this.filterModalIsOpen = true;
    },
    closeFilterModal() {
      this.filterModalIsOpen = false;
    },
    gettingReady() {
      alert('준비중 입니다.');
    },
    getWineList(page) {
      // 통신 처리
      if (page !== this.pageInfo.current_page) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.pageInfo.current_page = page;
      // @TODO 로딩 중 화면
      this.show(page);
      this.updatePageItems(page).finally(() => {
        // @TODO 로딩 완료
      })
    },
    nextPage($state) {
      // https://peachscript.github.io/vue-infinite-loading/guide/start-with-hn.html
      // 통신 연동 부분
      if (this.pageInfo.current_page < this.pageInfo.total_page) {
        const page = this.pageInfo.current_page;
        this.show(page);
        this.updatePageItems(page).then(() => {
          this.pageInfo.current_page++;
        }).finally(() => {
          $state.loaded(); // 다음 페이지가 있을 경우
        });
      } else {
        alert('마지막 페이지 입니다.');
        $state.complete(); // 다음 페이지가 없을 경우
      }
    },
    async updatePageItems(page) {
      const start = (page - 1) * this.pageInfo.item_per_page;
      const end = start + this.pageInfo.item_per_page * 2; // 2페이지 분을 미리 로딩
      if (this.wineList.length < end) this.wineList.length = end;

      let last = this.totalSupply;
      for (let i = start; i < end; ++i) {
        const item = await this.market.fetchItemListed(i).catch(e => {
          if (e.reason !== "nonexistent token")
            console.debug(e);
          return undefined;
        });
        if (item === undefined) {
          last = i;
          break;
        }

        if (this.wineList.length < i) {
          // 전체 개수가 늘어남
          const length = this.wineList.length;
          this.wineList.push(...Array(i - length + 1));
        }

        this.$set(this.wineList, i, item);
      }

      if (last < this.totalSupply) {
          // 전체 개수가 줄어듬
          this.wineList = this.wineList.slice(0, last);
          this.totalSupply = last;
      }

      return [start, Math.min(start + this.pageInfo.item_per_page, last)];
    },
    show(page) {
      if (!this.$isMobile()) {
        const start = (page - 1) * this.pageInfo.item_per_page;
        const end = start + this.pageInfo.item_per_page;
        this.viewWineList = [];
        for (let i = start; i < end; ++i) {
          const item = this.wineList[i];
          if (item !== undefined) {
            const index = i - start;
            if (this.viewWineList.length <= i)
              this.viewWineList.length = index + 1;
            this.$set(this.viewWineList, index, item);
          }
        }
      } else {
        this.viewWineList = this.wineList;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/nft-list.scss';
</style>
